import React from "react";
import { graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Form from "../components/cf";
import Candela from "../images/Spark_Plug.png"
import MediaQuery from "react-responsive";
import {FaImdb, FaLinkedin, FaInstagram} from "react-icons/fa"
import {SiFiverr} from "react-icons/si"

const socials = [
  {
    "name" : "IMDB",
    "url"  : "https://www.imdb.com/name/nm1444691/",
    "icon" : FaImdb
  },
  {
    "name" : "Instagram",
    "url"  : "https://www.instagram.com/matt.t.parsons/",
    "icon" : FaInstagram
  },
  {
    "name" : "LinkedIn",
    "url"  : "https://www.linkedin.com/in/matthew-parsons-4435a68a/",
    "icon" : FaLinkedin
  },
  {
    "name" : "Fiverr",
    "url"  : "https://www.fiverr.com/mtparsons",
    "icon" : SiFiverr
  }
]

export default function Contact({data}) {

  const insta = data ? data.allInstagramContent.nodes : '';

  return (
    <Layout>
      <Seo title="Contact"/>
      <div className="container pb--30">
        <div className="row">
          <div className="col-12 col-md-6 pt--30">
            <h1>Get in touch</h1>
              <Form/>
          </div>
          <div style={{textAlign:'center'}} className="col-12 col-md-6 pt--30">
          <h1>Socials</h1>
          {socials.map((social, i) => (
            <>
            <MediaQuery maxWidth={768}>
            <div style={{display:'inline',height:'60px',padding:'5px',width:'100%'}} key={i}>
              <a href={social.url} target="_blank" rel="noreferrer">
                <social.icon style={{ color: 'black', fontSize: '40px' }} />
              </a>
            </div>
            </MediaQuery>
            <MediaQuery minWidth={769}>
            <div style={{display:'block',height:'60px',padding:'5px',width:'100%',textAlign:'center',margin:'0 auto'}} key={i}>
              <a href={social.url} target="_blank" rel="noreferrer">
                <social.icon style={{ color: 'black', fontSize: '40px' }} />
              </a>
            </div>
            </MediaQuery>
            </>
          ))}
          </div>
          <div className="col-12 col-md-6 pt--30">
            <h1>Instagram</h1>
            {insta.map((node, i) => (
              <a href={node.permalink} target="_blank" rel="noreferrer">
                <GatsbyImage 
                  className="col-12 col-md-4"
                  style={{objectFit:'cover',height:'100px',width:'100px'}}
                  image={node.localImage.childImageSharp.gatsbyImageData}
                  alt={node.caption}
                  key={i}
                />
              </a>
            ))}
          </div>
          <div style={{textAlign:'center'}} className="candela col-12 col-md-6 pt--30">
            <img className="candela" style={{maxHeight:'280px'}} src={Candela} alt="candela" />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
query {
  allInstagramContent(limit: 12) {
      nodes {
        caption
        media_url
        permalink
        localImage {
          childImageSharp {
            gatsbyImageData(
              height: 200,
              layout: CONSTRAINED,
              placeholder: BLURRED
            )
          }
        }
      }
  }
}
`
