import React, { Component, Fragment } from "react";
import * as emailjs from "emailjs-com";

class ContactForm extends Component{
    state = {
        name: '',
        email: '',
        message: '',
      }
      handleSubmit(e) {
        e.preventDefault();
        const { name, email, message } = this.state
        let templateParams = {
          from_name: name,
          reply_to: email,
          message_html: message,
         }
         const serviceID = "matt_service";
         const templateID = "matt_template";
         const userID = "user_TrN4o6L9Bz5uLWPNKVIon";

         emailjs.send(
          serviceID,
          templateID,
          templateParams,
          userID
         )
         .then((response) => {
          this.resetForm();
          console.log(response);
          document.getElementById("res").style.background = "limegreen";
          document.getElementById("resTxt").innerHTML = "SENT!";
          window.setTimeout(function(){
            document.getElementById("resTxt").innerHTML = "SEND";
            document.getElementById("res").style.background = "#fff";
          }, 4000);
       }, (err) => {
          this.resetForm();
          console.log(err);
          document.getElementById("res").style.background = "red";
          document.getElementById("resTxt").innerHTML = "ERROR";
          window.setTimeout(function(){
            document.getElementById("resTxt").innerHTML = "SEND";
            document.getElementById("res").style.background = "#fff";
          }, 4000);
       });
     }
    
     resetForm() {
      this.setState({
        name: '',
        email: '',
        message: '',
      })
    }
    handleChange = (param, e) => {
      this.setState({ [param]: e.target.value })
    }
    
    wait(ms){
      var start = new Date().getTime();
      var end = start;
      while(end < start + ms) {
        end = new Date().getTime();
     }
    }

    render(){
        return(
          <Fragment>
          <form className="form" style={{fontFamily:'Bebas Neue'}} autoComplete="off" onSubmit={this.handleSubmit.bind(this)} method="POST">
            <input type="text" name="name" onChange={this.handleChange.bind(this, 'name')} spellCheck="false" placeholder="Your Name" value={this.state.name} required />
            <input type="email" name="email" spellCheck="false" placeholder="Your Email" value={this.state.email} onChange={this.handleChange.bind(this, 'email')} required />
            <textarea rows="4" cols="50" type="text" name="message" spellCheck="false" placeholder="Your Message" value={this.state.message} onChange={this.handleChange.bind(this, 'message')} required />
            <button type="submit" id="res" className="button">
              <div id="resTxt">SEND</div>
            </button>
          </form>
          </Fragment>
        )
    }
}
export default ContactForm;
